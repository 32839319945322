<script setup lang="ts">
defineLayout({
  label: 'Empty',
})

useAtlantisHead()

useSeoMeta({
  title: 'Bahamas Vacation Resort | Luxury Resort | Atlantis Bahamas',
  ogTitle: 'Bahamas Vacation Resort | Luxury Resort | Atlantis Bahamas',
  description: 'Experience Atlantis Paradise Island resorts entertainment and adventures. See what activities and amenities this Bahamas vacation destination has to offer.',
  ogDescription: 'Experience Atlantis Paradise Island resorts entertainment and adventures. See what activities and amenities this Bahamas vacation destination has to offer.',
  ogImage: 'https://www.atlantisbahamas.com/storage/media/atlantis-property-shot-royal-hero-sky.jpg',
  ogUrl: 'https://www.atlantisbahamas.com',
})

// Generated much of this at https://realfavicongenerator.net
useHead({
  htmlAttrs: {
    lang: 'en-US',
  },
  meta: [
    { name: 'msapplication-TileColor', content: '#000000' },
    { name: 'msapplication-config', content: '/favicons/atlantisbahamas/browserconfig.xml' },
    { name: 'theme-color', content: '#000000' },
  ],
  link: [
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/favicons/atlantisbahamas/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicons/atlantisbahamas/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicons/atlantisbahamas/favicon-16x16.png' },
    { rel: 'manifest', href: '/favicons/atlantisbahamas/site.webmanifest' },
    { rel: 'mask-icon', href: '/favicons/atlantisbahamas/safari-pinned-tab.svg', color: '#5bbad5' },
    { rel: 'shortcut icon', href: '/favicons/atlantisbahamas/favicon.ico' },
  ],
  script: [
    { src: '//fast.wistia.com/assets/external/E-v1.js', async: true },
  ],
})

const route = useRoute()
const nochrome = route.query.nochrome
</script>

<template>
  <div id="app" class="relative min-h-screen empty-theme">
    <div id="glue" class="text-zinc-700 bg-white" :class="{ nochrome }">
      <div class="relative z-40">
        <div id="portal-modal" />
      </div>

      <div class="flex justify-center">
        <div>
          <div>
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
